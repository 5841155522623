#sidebar.active .sidebar-wrapper {
  left: 0;
}
#sidebar:not(.active) .sidebar-wrapper {
  left: -300px;
}
#sidebar:not(.active) ~ #main {
  margin-left: 0;
}

#app {
  background-color: #f2f7ff;
}
#main.layout-navbar {
  padding: 0;
}

.navbar-dashboard {
  background-color: #f2f7ff !important;
}

.navbar .user-menu img {
  width: 39px;
  height: 39px;
}

.navbar.navbar-header li {
  display: flex;
  align-items: center;
}
.navbar.navbar-header li.nav-icon {
  margin-right: 0.4rem;
}
.navbar.navbar-header li.nav-icon .nav-link {
  display: block;
  padding: 0.4rem;
  border-radius: 50%;
}
.navbar.navbar-header li.nav-icon .nav-link:hover {
  background-color: #e9ecef;
}
.navbar.navbar-header .dropdown > a {
  color: #6c757d;
  font-weight: 600;
}
.navbar.navbar-header .dropdown > a svg {
  height: 24px;
  width: 24px;
}
.navbar.navbar-header .dropdown > a:after {
  display: none;
}

.avatar .avatar-content {
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
}
.avatar .avatar-content i,
.avatar .avatar-content svg {
  color: #fff;
  font-size: 1rem;
  height: 1rem;
}
.avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.avatar .avatar-status {
  width: 0.7rem;
  height: 0.7rem;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #fff;
  bottom: 0;
  right: 0;
}
.avatar.avatar-sm .avatar-content,
.avatar.avatar-sm img {
  width: 24px;
  height: 24px;
  font-size: 0.8rem;
}
.avatar.avatar-md .avatar-content,
.avatar.avatar-md img {
  width: 32px;
  height: 32px;
  font-size: 0.8rem;
}
.avatar.avatar-lg .avatar-content,
.avatar.avatar-lg img {
  width: 48px;
  height: 48px;
  font-size: 1.2rem;
}
.avatar.avatar-xl .avatar-content,
.avatar.avatar-xl img {
  width: 60px;
  height: 60px;
  font-size: 1.4rem;
}

@media (max-height: 600px) {
  .sidebar-menu {
    height: 73% !important;
  }
}

.sidebar-menu {
  height: 85%;
}

.sidebar-wrapper {
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  overflow-y: auto;
  background-color: #fff;
  bottom: 0;
  transition: left 0.5s ease-out;
}
.sidebar-wrapper .sidebar-header {
  padding: 1rem 2rem 1rem;
  font-size: 2rem;
  font-weight: 700;
}
.sidebar-wrapper .sidebar-header img {
  height: 1.2rem;
}
.sidebar-wrapper .sidebar-toggler.x {
  position: absolute;
  right: 0;
  top: 0.5rem;
  display: none;
}
.sidebar-wrapper .menu {
  margin-top: 2rem;
  padding: 0 2rem;
  font-weight: 600;
}
.sidebar-wrapper .menu .sidebar-title {
  padding: 0 1rem;
  margin: 1.5rem 0 1rem;
  font-size: 1rem;
  list-style: none;
  font-weight: 600;
  color: #25396f;
}
.sidebar-wrapper .menu .sidebar-link {
  display: block;
  padding: 0.7rem 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  transition: all 0.5s;
  text-decoration: none;
  color: #25396f;
}
.sidebar-wrapper .menu .sidebar-link i,
.sidebar-wrapper .menu .sidebar-link svg {
  color: #7c8db5;
}
.sidebar-wrapper .menu .sidebar-link span {
  margin-left: 1rem;
}

.sidebar-wrapper .menu .sidebar-item {
  list-style: none;
  margin-top: 0.5rem;
  position: relative;
  cursor: pointer;
}

.sidebar-wrapper .menu .sidebar-item.active .sidebar-link {
  background-color: #F7471E;
}
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link span {
  color: #fff;
}
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link i,
.sidebar-wrapper .menu .sidebar-item.active .sidebar-link svg {
  fill: #fff;
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .sidebar-wrapper {
    position: absolute;
    left: -300px;
  }
  .sidebar-wrapper .sidebar-toggler.x {
    display: block;
  }

  .navbar-dashboard {
    background-color: #fff !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

textarea {
  resize: none;
}

.exam-profile {
  padding: 4px;
  background-color: #F7471E;
  cursor: pointer;
}

.exam-profile:hover {
  padding: 0;
}

.search:valid {
  background-color: #e9ecef !important;
  border: none;
  outline: none;
  width: 290px;
  margin-left: 5px;
}

.search-container {
  background-color: #f2f7ff;
}

.search {
  padding-left: 20px;
  width: 300px;
  height: 40px;
  border-radius: 50px;
}

.search-student {
  padding-left: 20px;
  width: 300px;
  height: 40px;
  border-radius: 50px;
}

.search-student:valid {
  background-color: #e9ecef !important;
  border: none;
  outline: none;
  width: 100%;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .search-student:valid {
    width: 100%;
  }
  /* .glance-evaluation-parent {
    padding: 2px 30px 2px 30px;
  } */
  .glance-evaluation {
    font-size: 16px !important;
  }

  .profile-exam {
    width: 100% !important;
  }
  .exam-profile {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 767px) {
  .filter-btn {
    margin-bottom: 4px;
  }
}

@media (max-width: 355px) {
  .glance-evaluation-parent {
    padding: 5px 15px 5px 15px !important;
    font-size: 12px !important;
  }
  /* .glance-evaluation {
    font-size: 16px !important;
  } */
}

.orange-color  {
  background-color : #F7471E !important;
  color: white;
}

/* .alert {
    border: none;
  }
  .alert p {
    margin-bottom: 0;
  }
  .alert button.close {
    padding: 0.75rem;
    line-height: 0.75;
  }
  .alert .alert-heading,
  .alert .alert-heading + p {
    margin-left: 0.4rem;
  }
  .alert-primary {
    background-color: #435ebe;
    color: #fff;
  }
  .alert-primary a {
    color: #fff;
    font-weight: 700;
  }
  .alert-secondary {
    background-color: #ebeef3;
    color: #383d41;
  }
  .alert-secondary a {
    color: #fff;
    font-weight: 700;
  }
  .alert-success {
    background-color: #4fbe87;
    color: #fff;
  }
  .alert-success a {
    color: #fff;
    font-weight: 700;
  }
  .alert-warning {
    background-color: #eaca4a;
    color: #fff;
  }
  .alert-warning a {
    color: #fff;
    font-weight: 700;
  }
  .alert-danger {
    background-color: #f3616d;
    color: #fff;
  }
  .alert-danger a {
    color: #fff;
    font-weight: 700;
  }
  .alert-dark {
    background-color: #454546;
    color: #fff;
  }
  .alert-dark a {
    color: #fff;
    font-weight: 700;
  }
  .alert-light {
    background-color: #f9f9f9;
    color: #818182;
  }
  .alert-light a {
    color: #fff;
    font-weight: 700;
  }
  .alert-info {
    background-color: #56b6f7;
    color: #fff;
  }
  .alert-info a {
    color: #fff;
    font-weight: 700;
  }
  .alert-light-primary {
    background-color: #ebf3ff;
  }
  .alert-light-secondary {
    background-color: #e6eaee;
  }
  .alert-light-success {
    background-color: #d2ffe8;
  }
  .alert-light-danger {
    background-color: #ffdede;
  }
  .alert-light-warning {
    background-color: #fffdd8;
  }
  .alert-light-info {
    background-color: #e6fdff;
  }
  .avatar {
    display: inline-flex;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    position: relative;
  }
  .avatar .avatar-content {
    width: 32px;
    height: 32px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
  }
  .avatar .avatar-content i,
  .avatar .avatar-content svg {
    color: #fff;
    font-size: 1rem; */
/* height: 1rem;
  } */
/* .avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  } */
/* .avatar .avatar-status {
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    border-radius: 50%;
    border: 1px solid #fff;
    bottom: 0;
    right: 0;
  } */
/* .avatar.avatar-sm .avatar-content,
  .avatar.avatar-sm img {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  } */
/* .avatar.avatar-md .avatar-content,
  .avatar.avatar-md img {
    width: 32px;
    height: 32px;
    font-size: 0.8rem;
  } */
/* .avatar.avatar-lg .avatar-content,
  .avatar.avatar-lg img {
    width: 48px;
    height: 48px;
    font-size: 1.2rem;
  } */
/* .avatar.avatar-xl .avatar-content,
  .avatar.avatar-xl img {
    width: 60px;
    height: 60px;
    font-size: 1.4rem;
  } */
/* .btn .badge {
    border-radius: 50%;
    margin-left: 5px;
  } */
/* .btn .badge.bg-transparent {
    background-color: hsla(0, 0%, 100%, 0.25) !important;
    color: #fff;
  } */
/* .btn i,
  .btn svg {
    width: 16px;
    height: 16px;
  }
  .btn.icon {
    padding: 0.4rem 0.6rem;
  }
  .btn.icon svg {
    width: 16px;
    height: 16px;
  }
  .btn.icon.icon-left svg {
    margin-right: 3px;
  }
  .btn.icon.icon-right svg {
    margin-left: 3px;
  }
  .btn.btn-outline-white {
    color: #fff;
    border-color: #fff;
  }
  .btn.btn-outline-white:hover {
    color: #333;
    background-color: #fff;
  }
  .btn.btn-light-primary {
    background-color: #ebf3ff;
    color: #002152;
  }
  .btn.btn-light-secondary {
    background-color: #e6eaee;
    color: #181e24;
  }
  .btn.btn-light-success {
    background-color: #d2ffe8;
    color: #00391c;
  }
  .btn.btn-light-danger {
    background-color: #ffdede;
    color: #450000;
  }
  .btn.btn-light-warning {
    background-color: #fffdd8;
    color: #3f3c00;
  }
  .btn.btn-light-info {
    background-color: #e6fdff;
    color: #00474d;
  }
  .btn.btn-danger,
  .btn.btn-info,
  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-success,
  .btn.btn-warning {
    color: #fff;
  }
  .btn.btn-light {
    color: #607080;
  }
  .btn.btn-dark {
    color: #fff;
  }
  .btn-block {
    width: 100%;
  }
  .btn-group:not(.dropdown) .btn:not([class*="btn-"]) {
    border: 1px solid #dfe3e7;
  }
  .btn-group > .btn {
    border-radius: 0.267rem;
  }
  .buttons .btn {
    margin: 0 10px 10px 0;
  }
  .breadcrumb.breadcrumb-right {
    justify-content: flex-end;
    margin-top: 1rem;
  }
  .breadcrumb.breadcrumb-center {
    justify-content: center;
    margin-top: 1rem;
  } */
/* .carousel-inner {
    border-radius: 0.7rem;
  } */
.carousel-caption h5 {
  color: #fff;
}
.card {
  margin-bottom: 2.2rem;
  border: none;
}
.card.card-statistic {
  box-shadow: 1px 2px 5px rgba(47, 170, 244, 0.5);
  background: linear-gradient(180deg, #25a6f1, #54b9ff);
}
.card.card-statistic .card-title {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.8px;
  font-weight: 400;
  font-size: 1.3rem;
  margin-bottom: 0;
  margin-top: 5px;
}
.card.card-statistic .card-right p {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0;
}
.card.card-statistic .card-right span.green {
  color: #6fff6f;
}
.card.card-statistic .card-right span.red {
  color: #ff7979;
}
.card.card-statistic .chart-wrapper {
  height: 100px;
}
.card .card-header {
  border: none;
}
.card .card-header h4 {
  font-size: 1.2rem;
  font-weight: 700;
}
.card .card-header ~ .card-body {
  padding-top: 0;
}
.card .card-content {
  position: relative;
}
.card .card-title {
  font-size: 1.2rem;
}
.card .card-body {
  padding: 1.5rem 1.5rem 0 1.5rem;
}
.card .card-heading {
  color: #555;
  font-size: 1.5rem;
}
.card .card-img-overlay {
  background-color: rgba(0, 0, 0, 0.6);
}
.card .card-img-overlay p {
  color: #eee;
}
.card .card-img-overlay .card-title {
  color: #fff;
}
.pricing .card {
  box-shadow: none;
  border-right: 1px solid #e9ecef;
  box-shadow: 0 10px 10px #e9ecef;
  margin-bottom: 0.5rem;
}
.pricing h1 {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 3rem;
}
.pricing .card-header .card-title {
  font-size: 2rem !important;
  margin-bottom: 0;
}
.pricing .card-header p {
  font-size: 0.8rem;
}
.pricing ul li {
  list-style: none;
  margin-bottom: 0.5rem;
}
.pricing ul li i,
.pricing ul li svg {
  width: 1rem;
  color: #198754;
  font-size: 1rem;
  margin-right: 7px;
}
.pricing .card-highlighted {
  background-color: #435ebe;
  padding-top: 20px;
  padding-bottom: 20px;
}
.pricing .card-highlighted .card-body,
.pricing .card-highlighted .card-header {
  background-color: #435ebe;
  color: #fff;
}
.pricing .card-highlighted ul li {
  color: #fff;
}
.pricing .card-highlighted ul li i,
.pricing .card-highlighted ul li svg {
  color: #479f76;
}
.pricing .card-highlighted .card-footer {
  background-color: #435ebe;
}
.pricing .card-highlighted .card-title {
  color: #fff;
  font-size: 1.8rem;
}
.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  margin: 1rem 0;
}
.divider .divider-text {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
  background-color: #fff;
}
.divider .divider-text:after,
.divider .divider-text:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  border-top: 1px solid #dfe3e7;
}
.divider .divider-text:before {
  right: 100%;
}
.divider .divider-text:after {
  left: 100%;
}
.divider.divider-left .divider-text {
  left: 0;
  float: left;
  padding-left: 0;
}
.divider.divider-left-center .divider-text {
  left: -25%;
}
.divider.divider-right-center .divider-text {
  left: 25%;
}
.divider.divider-right .divider-text {
  float: right;
  padding-right: 0;
}
.dropdown-toggle:after {
  color: #fff;
}

.toggle-bar-btn {
  width: 30px;
  cursor: pointer;
}
.dropdown-menu-large {
  min-width: 16rem;
}
.dropdown-menu {
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
}
.dropdown-item {
  transition: all 0.5s;
}
.form-group {
  margin-bottom: 0.7rem;
}
.form-group label {
  color: rgba(35, 28, 99, 0.7);
  font-weight: 600;
}
.form-group small {
  font-size: 0.7rem;
}
.form-group.with-title {
  position: relative;
}
.form-group.with-title label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
  font-size: 0.6rem;
  background-color: #e9ecef;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-group.with-title .form-control,
.form-group.with-title dataTable-input {
  padding-top: 2rem;
}
.form-group.with-title .form-control:focus ~ label,
.form-group.with-title dataTable-input:focus ~ label {
  border-left: 1px solid #435ebe;
  border-top: 1px solid #435ebe;
  border-right: 1px solid #435ebe;
}
.form-group[class*="has-icon-"].has-icon-left .form-control {
  padding-left: 2.5rem;
}
.form-group[class*="has-icon-"].has-icon-left .form-control-icon {
  left: 0;
}
.form-group[class*="has-icon-"].has-icon-right .form-control {
  padding-right: 2.5rem;
}
.form-group[class*="has-icon-"].has-icon-right .form-control-icon {
  right: 0;
}
.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon i,
.form-group[class*="has-icon-"] .form-control:focus ~ .form-control-icon svg {
  color: #5a8dee;
}
.form-group[class*="has-icon-"] .form-control.form-control-xl {
  padding-left: 3rem;
}
.form-group[class*="has-icon-"]
  .form-control.form-control-xl
  ~ .form-control-icon
  i {
  font-size: 1.6rem;
}
.form-group[class*="has-icon-"]
  .form-control.form-control-xl
  ~ .form-control-icon
  i:before {
  color: #a6a8aa;
}
.form-group[class*="has-icon-"] .form-control-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 0.6rem;
}
.form-group[class*="has-icon-"] .form-control-icon i,
.form-group[class*="has-icon-"] .form-control-icon svg {
  width: 1.2rem;
  color: #6c757d;
  font-size: 1.2rem;
}
.form-group[class*="has-icon-"] .form-control-icon i:before,
.form-group[class*="has-icon-"] .form-control-icon svg:before {
  vertical-align: sub;
}
.form-control.form-control-xl {
  padding: 0.85rem 1rem;
  font-size: 1.2rem;
}
.form-check .form-check-input[class*="bg-"] {
  border: 0;
}
.form-check .form-check-input:focus {
  box-shadow: none;
}
.form-check .form-check-input.form-check-primary {
  background-color: #435ebe;
  border-color: #435ebe;
}
.form-check .form-check-input.form-check-primary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-primary.form-check-glow {
  box-shadow: 0 0 5px #697ecb;
}
.form-check .form-check-input.form-check-primary.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}
.form-check .form-check-input.form-check-secondary:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-secondary.form-check-glow {
  box-shadow: 0 0 5px #868e96;
}
.form-check
  .form-check-input.form-check-secondary.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-success {
  background-color: #198754;
  border-color: #198754;
}
.form-check .form-check-input.form-check-success:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-success.form-check-glow {
  box-shadow: 0 0 5px #21b26f;
}
.form-check .form-check-input.form-check-success.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-info {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.form-check .form-check-input.form-check-info:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-info.form-check-glow {
  box-shadow: 0 0 5px #3cd5f4;
}
.form-check .form-check-input.form-check-info.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}
.form-check .form-check-input.form-check-warning:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-warning.form-check-glow {
  box-shadow: 0 0 5px #ffce3a;
}
.form-check .form-check-input.form-check-warning.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}
.form-check .form-check-input.form-check-danger:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-danger.form-check-glow {
  box-shadow: 0 0 5px #e4606d;
}
.form-check .form-check-input.form-check-danger.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-light {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.form-check .form-check-input.form-check-light:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-light.form-check-glow {
  box-shadow: 0 0 5px #fff;
}
.form-check .form-check-input.form-check-light.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check .form-check-input.form-check-dark {
  background-color: #212529;
  border-color: #212529;
}
.form-check .form-check-input.form-check-dark:not(:checked) {
  background-color: transparent;
  border: 1px solid #ced4da;
}
.form-check .form-check-input.form-check-dark.form-check-glow {
  box-shadow: 0 0 5px #383f45;
}
.form-check .form-check-input.form-check-dark.form-check-glow:not(:checked) {
  box-shadow: none;
}
.form-check.form-check-sm .form-check-input {
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 0.3em;
}
.form-check.form-check-sm label {
  font-size: 0.7rem;
}
.form-check.form-check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.3em;
}
.form-check.form-check-lg label {
  font-size: 1rem;
}
.form-check.form-check-primary .form-check-input {
  background-color: #435ebe;
  border-color: #435ebe;
}
.form-check.form-check-secondary .form-check-input {
  background-color: #6c757d;
  border-color: #6c757d;
}
.form-check.form-check-success .form-check-input {
  background-color: #198754;
  border-color: #198754;
}
.form-check.form-check-info .form-check-input {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.form-check.form-check-warning .form-check-input {
  background-color: #ffc107;
  border-color: #ffc107;
}
.form-check.form-check-danger .form-check-input {
  background-color: #dc3545;
  border-color: #dc3545;
}
.form-check.form-check-light .form-check-input {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.form-check.form-check-dark .form-check-input {
  background-color: #212529;
  border-color: #212529;
}
.dataTable-input {
  min-height: calc(1.5em + 0.934rem + 2px);
  padding: 0.467rem 0.6rem;
  font-size: 0.9025rem;
  font-weight: 400;
  line-height: 1.5;
  color: #555252;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe3e7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.dataTable-input:focus {
  color: #555252;
  background-color: #fff;
  border-color: #5a8dee;
  outline: 0;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}
.modal .modal-content {
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.13);
  border: none;
}
.modal .modal-full {
  max-width: 94%;
}
.modal .white {
  color: #fff;
}
.modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal .modal-header .modal-title {
  font-size: 1.1rem;
}
.modal .modal-header .close {
  padding: 7px 10px;
  border-radius: 50%;
  background: none;
  border: none;
}
.modal .modal-header .close:hover {
  background: #dee2e6;
}
.modal .modal-header i,
.modal .modal-header svg {
  font-size: 12px;
  height: 12px;
  width: 12px;
}
.modal .modal-footer {
  padding: 1rem;
}
.modal.modal-borderless .modal-header {
  border-bottom: 0;
}
.modal.modal-borderless .modal-footer {
  border-top: 0;
}

.subscription-bg,
.subscription-btn-bg {
  background-color: #F7471E;
}

.broder-subscription {
  border: 1px solid #F7471E;
  display: none;
}

.add-subscription-btn {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .broder-subscription {
    display: block;
  }
}

.add-subscription-btn i {
  color: #F7471E;
}
.nav-pills .nav-link.active {
  box-shadow: 0 2px 10px rgba(67, 94, 190, 0.5);
}

.nav-tabs,
.nav-tabs .nav-link {
  border: none;
}
.nav-tabs .nav-link:hover {
  border: none;
  text-shadow: 0 0 2px rgba(67, 94, 190, 0.3);
}
.nav-tabs .nav-link.active {
  border: none;
  position: relative;
  color: #435ebe;
}
.nav-tabs .nav-link.active:after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #435ebe;
  left: 0;
  box-shadow: 0 2px 5px rgba(67, 94, 190, 0.5);
}
.navbar-fixed {
  position: fixed;
  background-color: #fff;
}
.navbar {
  height: 90px;
  padding: 1.5rem;
}
.navbar .navbar-brand img {
  height: 1.5rem;
}
.navbar .user-menu img {
  width: 39px;
  height: 39px;
}
.navbar.navbar-header li {
  display: flex;
  align-items: center;
}
.navbar.navbar-header li.nav-icon {
  margin-right: 0.4rem;
}
.navbar.navbar-header li.nav-icon .nav-link {
  display: block;
  padding: 0.4rem;
  border-radius: 50%;
}
.navbar.navbar-header li.nav-icon .nav-link:hover {
  background-color: #e9ecef;
}
.navbar.navbar-header .dropdown > a {
  color: #6c757d;
  font-weight: 600;
}
.navbar.navbar-header .dropdown > a svg {
  height: 24px;
  width: 24px;
}
.navbar.navbar-header .dropdown > a:after {
  display: none;
}
.pagination.pagination-primary .page-item.active .page-link {
  background-color: #435ebe;
  border-color: #435ebe;
  box-shadow: 0 2px 5px rgba(67, 94, 190, 0.3);
}
.pagination.pagination-secondary .page-item.active .page-link {
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 2px 5px rgba(108, 117, 125, 0.3);
}
.pagination.pagination-success .page-item.active .page-link {
  background-color: #198754;
  border-color: #198754;
  box-shadow: 0 2px 5px rgba(25, 135, 84, 0.3);
}
.pagination.pagination-info .page-item.active .page-link {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: 0 2px 5px rgba(13, 202, 240, 0.3);
}
.pagination.pagination-warning .page-item.active .page-link {
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 2px 5px rgba(255, 193, 7, 0.3);
}
.pagination.pagination-danger .page-item.active .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: 0 2px 5px rgba(220, 53, 69, 0.3);
}
.pagination.pagination-light .page-item.active .page-link {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 2px 5px rgba(248, 249, 250, 0.3);
}
.pagination.pagination-dark .page-item.active .page-link {
  background-color: #212529;
  border-color: #212529;
  box-shadow: 0 2px 5px rgba(33, 37, 41, 0.3);
}
.page-item:not(.active) .page-link:hover {
  color: #000;
}
.page-item i,
.page-item svg {
  font-size: 13px;
  width: 13px;
  height: 13px;
}
.page-item .page-link {
  font-size: 0.875rem;
}
.page-item .page-link:focus {
  box-shadow: none;
}
.page-item:first-child {
  margin-right: 0.4rem;
}
.page-item:last-child {
  margin-left: 0.4rem;
}
.dataTable-table td,
.dataTable-table thead th,
.table td,
.table thead th {
  vertical-align: middle;
}
.dataTable-table:not(.table-borderless) thead th,
.table:not(.table-borderless) thead th {
  border-bottom: 1px solid #dedede !important;
}
.table-md.dataTable-table tr td,
.table-md.dataTable-table tr th,
.table-sm.dataTable-table tr td,
.table-sm.dataTable-table tr th,
.table.table-md tr td,
.table.table-md tr th,
.table.table-sm tr td,
.table.table-sm tr th {
  padding: 1rem;
}
.table-lg.dataTable-table tr td,
.table-lg.dataTable-table tr th,
.table.table-lg tr td,
.table.table-lg tr th {
  padding: 1.3rem;
}
.dataTable-container {
  overflow-x: auto;
}
.progress.progress-primary {
  overflow: visible;
}
.progress.progress-primary .progress-bar {
  background-color: #435ebe;
  border-radius: 0.25rem;
}
.progress.progress-secondary {
  overflow: visible;
}
.progress.progress-secondary .progress-bar {
  background-color: #6c757d;
  border-radius: 0.25rem;
}
.progress.progress-success {
  overflow: visible;
}
.progress.progress-success .progress-bar {
  background-color: #198754;
  border-radius: 0.25rem;
}
.progress.progress-info {
  overflow: visible;
}
.progress.progress-info .progress-bar {
  background-color: #0dcaf0;
  border-radius: 0.25rem;
}
.progress.progress-warning {
  overflow: visible;
}
.progress.progress-warning .progress-bar {
  background-color: #ffc107;
  border-radius: 0.25rem;
}
.progress.progress-danger {
  overflow: visible;
}
.progress.progress-danger .progress-bar {
  background-color: #dc3545;
  border-radius: 0.25rem;
}
.progress.progress-light {
  overflow: visible;
}
.progress.progress-light .progress-bar {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
}
.progress.progress-dark {
  overflow: visible;
}
.progress.progress-dark .progress-bar {
  background-color: #212529;
  border-radius: 0.25rem;
}
.progress.progress-sm {
  height: 0.4rem;
}
.progress.progress-lg {
  height: 1.5rem;
}
.progress .progress-bar {
  position: relative;
  overflow: visible;
}
.progress .progress-bar.progress-label:before {
  content: attr(aria-valuenow) "%";
  position: absolute;
  right: 0;
  top: -1.3rem;
  color: #495057;
  font-size: 0.8rem;
}
.bi {
  width: 1rem;
  height: 1rem;
}
.bi.bi-middle:before {
  vertical-align: middle;
}
.bi.bi-sub:before {
  vertical-align: sub;
}
.stats-icon {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: #000;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stats-icon i {
  color: #fff;
  font-size: 1.7rem;
}
.stats-icon.purple {
  background-color: #9694ff;
}
.stats-icon.blue {
  background-color: #57caeb;
}
.stats-icon.red {
  background-color: #ff7976;
}
.stats-icon.green {
  background-color: #5ddab4;
}
@media (max-width: 767px) {
  .stats-icon {
    float: left;
    margin-bottom: 0.4rem;
  }
}
.burger-btn {
  display: none;
}
#main {
  margin-left: 300px;
  height: 100vh;
  overflow-y: scroll;
}
@media screen and (max-width: 1199px) {
  #main {
    margin-left: 0;
  }
}
#main.layout-navbar {
  padding: 0;
}

@media (max-width: 767px) {
  #main.layout-navbar {
    padding-bottom: 4rem;
  }
}
#main #main-content {
  padding: 2rem;
}
.page-heading {
  margin: 0 0 2rem;
}
.page-heading h3 {
  font-weight: 700;
}
.page-title-headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.page-title-headings h3 {
  margin-bottom: 0;
  margin-right: 1rem;
}
.page-title-headings .breadcrumb {
  margin-bottom: 0;
}
a {
  text-decoration: none;
}
.mt-10 {
  margin-top: 3rem;
}
.mb-10,
.my-10 {
  margin-bottom: 3rem;
}
.my-10 {
  margin-top: 3rem;
}
.mb-24,
.my-24 {
  margin-bottom: 6rem;
}
.my-24 {
  margin-top: 6rem;
}
.opacity-50 {
  opacity: 1%;
}
.py-4-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.text-sm {
  font-size: 0.875rem;
}
.text-xl {
  font-size: 1.25rem;
}
.text-4xl {
  font-size: 2.25rem;
}
.text-6xl {
  font-size: 4rem;
}
.text-black {
  color: #000;
}
.bg-gradient-ltr {
  background: linear-gradient(90deg, #095cde, #53c3f3);
}
.bg-light-primary {
  background-color: #ebf3ff;
  color: #002152;
}
.bg-light-secondary {
  background-color: #e6eaee;
  color: #181e24;
}
.bg-light-success {
  background-color: #d2ffe8;
  color: #00391c;
}
.bg-light-danger {
  background-color: #ffdede;
  color: #450000;
}
.bg-light-warning {
  background-color: #fffdd8;
  color: #3f3c00;
}
.bg-light-info {
  background-color: #e6fdff;
  color: #00474d;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.text-width-md {
  max-width: 450px;
}
.text-gray-300 {
  color: #dee2e6 !important;
}
.text-gray-400 {
  color: #ced4da !important;
}
.text-gray-500 {
  color: #adb5bd !important;
}
.text-gray-600 {
  color: #6c757d !important;
}
.btn-xl {
  padding: 1rem 2rem;
}
.icon-mid:before {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .sm-row-col-6-first {
    order: 1 !important;
    margin-bottom: 15px;
  }

  .sm-row-col-6-last {
    order: 2 !important;
  }
}

.dataTable-wrapper.no-header .dataTable-container {
  border-top: 1px solid #d9d9d9;
}

.dataTable-top,
.dataTable-bottom {
  padding: 8px 10px;
}

.dataTable-top > div:first-child,
.dataTable-bottom > div:first-child {
  float: left;
}

.dataTable-top > div:last-child,
.dataTable-bottom > div:last-child {
  float: right;
}

.dataTable-input {
  padding: 6px 12px;
}

.dataTable-info {
  margin: 7px 0;
}

.dataTable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  color: #333;
}

.dataTable-pagination a:hover {
  background-color: #d9d9d9;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #d9d9d9;
  cursor: default;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.dataTable-pagination .pager a {
  font-weight: bold;
}

/* TABLE */
.dataTable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.dataTable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-top: 1px solid #d9d9d9;
}

.dataTable-table th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th a {
  text-decoration: none;
  color: inherit;
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.dataTable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}

.dataTable-sorter::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

.dataTable-dropdown {
  display: inline-flex;
  align-items: center;
}

.dataTable-dropdown label {
  white-space: nowrap;
  margin-left: 15px;
}
