.linePreloader-green {
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, #F7471E, #b92403);
  background-color: transparent;
  position: fixed;
  background-size: 20%;
  left: 0;
  top: 0;
  z-index: 10;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: scroll 1.2s ease-in-out infinite;
}

@keyframes scroll {
  50% {
    background-size: 80%;
  }
  100% {
    background-position: 125% 0;
  }
}
